import React, { useState } from "react";
import styled from "styled-components";

const MailContainer = styled.div`
  border: 1px solid gray;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5rem;
  justify-content: space-between;

  p {
    font-weight: bold;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;

  input[type="text"],
  input[type="email"] {
    width: 50%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  div {
    justify-content: left;
  }
  @media (min-width: 600px) {
    width: 40%;
  }
`;

const SubmitButton = styled.input``;

const MailSignupForm = () => {
  const [emailAddress, setEmailAddress] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  return (
    <>
      <div id="mc_embed_signup">
        <form
          action="https://windomcommunitypto.us6.list-manage.com/subscribe/post?u=ae87b0aa54678ee2eb5d1074c&amp;id=f94dd487a6"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <MailContainer>
            <p>Subscribe for email updates</p>
            <LabelContainer>
              <div>
                <label for="mce-EMAIL">
                  Email Address <span class="asterisk">*</span>
                </label>
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email Address"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.value)}
                  name="EMAIL"
                  class="required email"
                  id="mce-EMAIL"
                />
              </div>
            </LabelContainer>
            <LabelContainer>
              <label for="mce-FNAME">First Name </label>
              <input
                type="text"
                placeholder="First name"
                size={20}
                onChange={(e) => setFirstName(e.value)}
                name="FNAME"
                class=""
                id="mce-FNAME"
                value={firstName}
              />
            </LabelContainer>
            <LabelContainer>
              <label for="mce-LNAME">Last Name </label>
              <input
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.value)}
                name="LNAME"
                class=""
                id="mce-LNAME"
              />
            </LabelContainer>
            <LabelContainer>
              <div class="mc-field-group input-group">
                <label>Grade(s)</label>
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      value="1"
                      name="group[55462][1]"
                      id="mce-group[55462]-55462-0"
                    />
                    <label for="mce-group[55462]-55462-0">K</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      value="2"
                      name="group[55462][2]"
                      id="mce-group[55462]-55462-1"
                    />
                    <label for="mce-group[55462]-55462-1">1st</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      value="4"
                      name="group[55462][4]"
                      id="mce-group[55462]-55462-2"
                    />
                    <label for="mce-group[55462]-55462-2">2nd</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      value="8"
                      name="group[55462][8]"
                      id="mce-group[55462]-55462-3"
                    />
                    <label for="mce-group[55462]-55462-3">3rd</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      value="16"
                      name="group[55462][16]"
                      id="mce-group[55462]-55462-4"
                    />
                    <label for="mce-group[55462]-55462-4">4th</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      value="32"
                      name="group[55462][32]"
                      id="mce-group[55462]-55462-5"
                    />
                    <label for="mce-group[55462]-55462-5">5th</label>
                  </li>
                </ul>
              </div>
            </LabelContainer>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_ae87b0aa54678ee2eb5d1074c_f94dd487a6"
                tabindex="-1"
                value=""
              />
            </div>
            <br />
            <div class="clear">
              <SubmitButton
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </MailContainer>
        </form>
      </div>
    </>
  );
};

export default MailSignupForm;
