import styled from "styled-components";

const MeetingMinutesContainer = styled.div`
  border: 1px solid gray;
  border-radius: 15px;
  min-height: 280px;
  margin: 10px 0px;
  padding: 8px;
`;

const MeetingMinutes = () => {
  return (
    <MeetingMinutesContainer>
      <h2>Meeting Minutes</h2>
      <h4>Thursday, September 16, 2021 - Membership Meeting</h4>
      <p>
        <a
          href="https://storage.googleapis.com/windompto-static-files/General%20Meeting%209.16.mp4"
          download
        >
          Zoom recording
        </a>
        <br />
        <a
          href="https://storage.googleapis.com/windompto-static-files/9-16-2021%20Windom%20PTO%20General%20Minutes.docx"
          download
        >
          Minutes
        </a>
        <br />
      </p>
    </MeetingMinutesContainer>
  );
};

export default MeetingMinutes;
