import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Layout from "./components/Layout";
import About from "./components/About/About";
import Events from "./components/Events/Events";
import Home from "./components/Home/Home";
import MeetingMinutes from "./components/MeetingMinutes/MeetingMinutes";

const CSSReset = styled.div`
  color: black;
`;

function App() {
  return (
    <CSSReset>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/events">
              <Events />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/meeting-minutes">
              <MeetingMinutes />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </CSSReset>
  );
}

export default App;
