import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import MailSignupForm from "./MailSignupForm";
import PaypalDonate from "./PaypalDonate";

// const LinkContainer = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// const LinkText = styled.span`
//   border-bottom: 2px solid #ccc;
//   font-size: 1.35rem;
//   margin: 0.75rem;
// `;

// const TopLinks = () => {
//   return (
//     <LinkContainer>
//       <Link to="/">
//         <LinkText>Home</LinkText>
//       </Link>
//       <Link to="/events">
//         <LinkText>Events</LinkText>
//       </Link>
//       <Link to="/about">
//         <LinkText>About</LinkText>
//       </Link>
//     </LinkContainer>
//   );
// };

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #ccc;
  margin: 8px;
  padding: 10px;
  border-top: 3px solid white;
`;

const FooterItem = styled.span`
  font-size: 0.8rem;
  margin: 8px 8px;
`;

const Footer = () => {
  return (
    <>
      <MailSignupForm />
      <FooterContainer>
        <FooterItem>
          <PaypalDonate />
        </FooterItem>
        <FooterItem>
          <a href="https://www.windompto.org">Windom PTO</a>
          &nbsp;&nbsp;
        </FooterItem>
        <FooterItem>
          <a
            href="https://windom.mpls.k12.mn.us/Home"
            target="_blank"
            rel="noopener noreferrer"
          >
            Windom Community School
          </a>
          &nbsp;&nbsp;
        </FooterItem>
        <FooterItem>
          <a href="mailto:windompto@gmail.com">windompto@gmail.com</a>
          &nbsp;&nbsp;
        </FooterItem>
        <FooterItem>
          <Link to="/meeting-minutes">Previous Meetings</Link>
          &nbsp;&nbsp;
        </FooterItem>
      </FooterContainer>
    </>
  );
};

const MainContainer = styled.div`
  background-color: #fff;
  font-family: "Helvetica";
  max-width: 800px;
  margin: auto;
  padding: 1.25rem;
`;

const WordmarkContainer = styled.div`
  display: flex;
  flex-direction: row;

  a {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
  }
`;

const LogoContainer = styled.div`
  border: 2px solid black;
  border-radius: 1000px;
  background-color: black;
  padding: 15px;
  margin-right: 5px;

  font-size: 52px;
  font-weight: bold;
  font-family: serif;
  color: white;
  text-align: center;

  width: 50px;
  height: 50px;
`;

const StyledWLogo = () => {
  return (
    <>
      <LogoContainer>W</LogoContainer>
    </>
  );
};

const Wordmark = () => {
  return (
    <>
      <WordmarkContainer>
        <a href="https://windompto.org/">
          <StyledWLogo />
          <h1>indom Community PTO</h1>
        </a>
      </WordmarkContainer>
      <h4>
        <em>Building community together</em>
      </h4>
    </>
  );
};

const ContentWrapper = styled.div`
  min-height: 300px;
`;
const Layout = ({ children }) => {
  return (
    <MainContainer>
      <Wordmark />
      {/* <TopLinks /> */}
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </MainContainer>
  );
};

export default Layout;
