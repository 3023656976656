import React from "react";
import styled from "styled-components";
import WindomImage from "./windom.jpeg";

const HomeContainer = styled.div`
  margin-top: 2rem;
`;

const ImageContainer = styled.img`
  max-width: 100%;
  max-height: 700px;
`;

const eventData = [
  {
    number: 2,
    title: "PTO Meeting",
    date: new Date(2021, 9, 14, 18),
    description: "Nominations for PTO Executive Board will be presented.",
  },
  {
    number: 3,
    title: "No school",
    date: new Date(2021, 9, 20),
    description: "",
  },
  {
    number: 4,
    title: "No school",
    date: new Date(2021, 9, 21),
    description: "",
  },
  {
    number: 5,
    title: "No school",
    date: new Date(2021, 9, 22),
    description: "",
  },
  {
    number: 6,
    title: "PTO Meeting",
    date: new Date(2021, 10, 18, 18),
    description: "PTO Executive Board Elections",
  },
];

const IndividualEvent = styled.div`
  margin: 10px 0;

  h3 {
    color: #9999ff;
  }
`;

const Event = ({ event }) => {
  return (
    <IndividualEvent>
      <h3>{event.title}</h3>
      <p>
        <strong>
          {Intl.DateTimeFormat("en", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            month: "long",
            weekday: "short",
            day: "numeric",
          }).format(event.date)}
        </strong>
        {event.description && " - "}
        {event.description}
      </p>
    </IndividualEvent>
  );
};

const EventsContainer = styled.div`
  border: 1px solid gray;
  border-radius: 15px;
  margin: 10px 0px;
  padding: 8px;
`;

const EventsTitle = styled.h2`
  color: blue;
`;

const UpcomingEvents = () => {
  return (
    <EventsContainer>
      <EventsTitle>Upcoming Events</EventsTitle>
      {eventData.map((evt) => (
        <Event event={evt} key={evt.number}></Event>
      ))}
    </EventsContainer>
  );
};

const PastNewslettersContainer = styled.div`
  margin: 10px 0px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 8px;

  ul {
    list-style-type: none;
  }

  h2 {
    color: red;
  }

`

const pastNewsletters = [
  {
    date: "October 12, 2021",
    link: "https://mailchi.mp/8581add53ef6/windom-pto-october-2021-newsletter?e=74cdaff4c5",
  },
]

const PastNewsletters = () => {
  return (
    <PastNewslettersContainer>
      <h2>Past Newsletters</h2>
      <ul>
        {pastNewsletters.map(newsletterData => {
          return (
            <li><a href={newsletterData.link} target="_blank" rel="noreferrer noopener">{newsletterData.date}</a></li>
          )
        })}
      </ul>
    </PastNewslettersContainer>
  )
}

const VolunteerOpportunitiesContainer = styled.div`
  margin: 10px 0px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 8px;
  ul {
    list-style-type: none;
  }

  h2 {
    color: red;
  }
`;

const VolunteerOpportunities = () => {
  return (
    <VolunteerOpportunitiesContainer>
      <h2>Volunteer Opportunities</h2>
      <ul>
        <li>
          <strong>
            <a
              href="https://www.signupgenius.com/go/20f0d4fa4af2aa1f85-windomlunch"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lunch helpers
            </a>
          </strong>
        </li>
        <li>
          <strong>
            <a
              href="https://www.signupgenius.com/go/20f0d4fa4af2aa1f85-windomcrossing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Crossing guard
            </a>
          </strong>
        </li>

        <li>
          <strong>
            <a
              href="https://m.signupgenius.com/#!/showSignUp/20f0d4fa4af2aa1f85-windom"
              target="_blank"
              rel="noopener noreferrer"
            >
              PTO Committees
            </a>
          </strong>
        </li>
      </ul>
    </VolunteerOpportunitiesContainer>
  );
};

const Home = () => {
  return (
    <HomeContainer>
      <ImageContainer src={WindomImage} alt="Windom Commuinity School" />
      <UpcomingEvents />
      <VolunteerOpportunities />
      <PastNewsletters />
    </HomeContainer>
  );
};

export default Home;
