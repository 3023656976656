import React from "react";

const PaypalDonate = () => {
  return (
    <>
      <a href="https://paypal.me/windompto" aria-label="Donate to Windom PTO via Paypal" target="_blank" rel="noopener noreferrer">
        <img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      </a>
    </>
  )
};

export default PaypalDonate;
